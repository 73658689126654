import React, { Fragment } from 'react';
import Header from '../components/header/Header';
import PageTitle from '../components/pagetitle/PageTitle';
import Footer from '../components/footer/Footer';
import Scrollbar from '../components/scrollbar/scrollbar';
import Facebook from '../images/icons/facebook.png';
import Twitter from '../images/icons/twitter.png';
import Instagram from '../images/icons/instagram-icon.png';
import Linkedin from '../images/icons/linkedin.png';
import Pinterest from '../images/icons/pinterest.png';
import Quora from '../images/icons/quora.png';
import Medium from '../images/icons/medium.png';
import Youtube from '../images/icons/youtube.png';
import Threads from '../images/icons/threads.png';
import Whatsapp from '../images/icons/whatsapp.png';
import Breadcumb from "../images/about/social-media.jpg"

const SocialMediaLinks = (props) => {


    const links = [
        {
            Id: '1',
            tImg: Facebook,
            slug: 'https://www.facebook.com/TeamJJFIndia/',
            title: 'Facebook',
        },
        {
            Id: '2',
            tImg: Twitter,
            slug: 'https://x.com/TeamJJFIndia/',
            title: 'Twitter',
        },
        {
            Id: '3',
            tImg: Instagram,
            slug: 'https://www.instagram.com/TeamJJFIndia/',
            title: 'Instagram',
        },
        {
            Id: '4',
            tImg: Linkedin,
            slug: 'https://www.linkedin.com/company/TeamJJFIndia/',
            title: 'Linkedin',
        },
        {
            Id: '5',
            tImg: Pinterest,
            slug: 'https://in.pinterest.com/TeamJJFIndia/',
            title: 'Pinterest',
        },
        {
            Id: '6',
            tImg: Quora,
            slug: 'https://www.quora.com/profile/TeamJJFIndia',
            title: 'Quora',
        },
        {
            Id: '7',
            tImg: Medium,
            slug: 'https://medium.com/@TeamJJFIndia',
            title: 'Medium',
        },
        {
            Id: '8',
            tImg: Youtube,
            slug: 'https://www.youtube.com/@TeamJJFIndia/',
            title: 'Youtube',
        },
        {
            Id: '9',
            tImg: Threads,
            slug: 'https://www.threads.net/@TeamJJFIndia/',
            title: 'Threads',
        },
        {
            Id: '10',
            tImg: Whatsapp,
            slug: 'https://wa.me/919552895289',
            title: 'Whatsapp',
        },

    ]

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Social Media Links'} pagesub={'Social Media Links'} bg={Breadcumb} />


                <section className="volunteersSection pt-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sectionTitle text-center mb-70" id='feature'>
                                    <span className="sectionTitle__small justify-content-center">
                                        <i className="fa-solid fa-heart btn__icon"></i>
                                        Connect, Inspire, Empower
                                    </span>
                                    <h2 className="sectionTitle__big">Follow Us on Social Media</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {links.slice(0, 10).map((team, tm) => (
                                <div className="col-lg-3 col-md-6 mb-20" key={tm}>
                                    <div className="volunteerBlock text-center">
                                        <figure className="volunteerBlock__figure">
                                            <a href={team.slug} target='_blank' rel="noreferrer">
                                                <img className="volunteerBlock__figure__thumb" style={{ objectFit: 'contain', filter:'none' }} width={156} src={team.tImg} alt={team.title} />
                                            </a>
                                        </figure>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </section>


            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default SocialMediaLinks;
