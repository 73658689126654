import React, { Fragment } from 'react';
import Header from '../components/header/Header';
import PageTitle from '../components/pagetitle/PageTitle';
import Footer from '../components/footer/Footer';
import Scrollbar from '../components/scrollbar/scrollbar';
import NewsletterImg from '../images/newsletter/newsletter-img.jpg';
import Breadcumb from "../images/about/social-media.jpg"
import { Link } from 'react-router-dom';

const Newsletter = (props) => {


    const newsletters = [
        {
          title: "Preserving Family Stories: The Power of Oral Histories",
          description:
            "Recording family stories ensures they are not lost with time. This edition highlights the importance of oral histories, provides tips for conducting meaningful interviews with relatives, and showcases inspiring stories of families who have preserved their heritage.",
          image: NewsletterImg,
        },
        {
          title: "Digitizing Family Records: A Step Towards Preservation",
          description:
            "Keeping old documents safe for future generations is crucial. Learn how to scan, organize, and digitally store family records, photos, and letters while maintaining their historical significance.",
          image: NewsletterImg,
        },
        {
          title: "Exploring Your Ancestry: Tools and Techniques",
          description:
            "Genealogy research is easier than ever with modern technology. This newsletter introduces the best online databases, DNA testing options, and research techniques to trace your lineage accurately.",
          image: NewsletterImg,
        },
        {
          title: "Connecting Generations Through Family Reunions",
          description:
            "Family reunions can be a powerful way to strengthen ties. Discover planning tips, activity ideas, and meaningful ways to make these gatherings a bridge between generations.",
          image: NewsletterImg,
        },
        {
          title: "Writing Your Family’s History: A Guide for Beginners",
          description:
            "Every family has a unique story worth telling. This issue offers guidance on how to structure and write a compelling family history, including templates and real-life examples.",
          image: NewsletterImg,
        },
        {
          title: "The Role of Temples in Family History Work",
          description:
            "Temples hold significant importance in honoring ancestors. Explore how temple ordinances connect past and present generations, and learn about sacred rituals that strengthen family bonds.",
          image: NewsletterImg,
        },
        {
          title: "Teaching Youth About Their Heritage",
          description: "Educating the next generation ensures family history work continues. This edition shares engaging ways to involve children and teens in discovering their roots, including games, projects, and storytelling sessions.",
          image: NewsletterImg,
        },
        {
          title: "Overcoming Roadblocks in Family History Research",
          description: "Hit a wall in your genealogy search? You’re not alone. Learn expert strategies for dealing with missing records, name variations, and language barriers while researching your ancestry.",
          image: NewsletterImg,
        },
        {
          title: "Temple and Family History Callings: Embracing Leadership",
          description: "Serving in a leadership role within temple and family history work is a great responsibility. This issue provides insights into fulfilling your calling effectively, mentoring others, and creating a thriving family history program.",
          image: NewsletterImg,
        },
        {
          title: "Restoring and Preserving Family Heirlooms",
          description: "Heirlooms tell the story of generations past. Learn best practices for cleaning, restoring, and displaying treasured items like journals, quilts, and jewelry while maintaining their integrity.",
          image: NewsletterImg,
        },
        {
          title: "Finding Lost Ancestors: Uncovering Hidden Family Ties",
          description: "Sometimes, key family connections seem impossible to find. This newsletter covers advanced genealogy research techniques, including DNA matching, migration records, and cross-referencing historical documents.",
          image: NewsletterImg,
        },
        {
          title: "Strengthening Faith Through Family History",
          description: "Family history is deeply connected to faith and spirituality. Read inspiring stories of individuals who have grown in faith by discovering their ancestors and how temple ordinances bring families together eternally.",
          image: NewsletterImg,
        },
        {
          title: "Utilizing FamilySearch and Other Digital Archives",
          description: "Technology makes genealogy research more accessible. Learn how to navigate FamilySearch, Ancestry.com, and other archives to uncover meaningful connections in your family tree.",
          image: NewsletterImg,
        },
        {
          title: "Leaving a Legacy: How to Ensure Your Family History Lives On",
          description: "What we do today shapes the memories of tomorrow. Discover ways to compile family records into published books, digital archives, and video documentaries that will be cherished for generations to come.",
          image: NewsletterImg,
        },
      ];

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Newsletter'} pagesub={'Newsletter'} bg={Breadcumb} />


                <section className="volunteersSection pt-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sectionTitle text-center mb-40" id='feature'>
                                    <span className="sectionTitle__small justify-content-center">
                                        <i className="fa-solid fa-heart btn__icon"></i>
                                        Bringing You Stories of Change & Impact!
                                    </span>
                                    <h2 className="sectionTitle__big">JJF India Newsletter</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {newsletters.map((newsletter, index) => (
                                <div key={index} className="col-lg-6 mb-30" style={{minHeight:'100%'}}>
                                    <div className="innerWrapper" style={{minHeight:'100%', position:'relative'}}>
                                        <div className="donationDetails storiesDetails">
                                            <div className="donationDetails__header mb-15">
                                                <figure className="thumb mb-45">
                                                    <img src={newsletter.image} alt="Newsletter" />
                                                </figure>
                                            </div>
                                            <h3 className="eventsBlock__heading">{newsletter.title}</h3>
                                            <p className="donationDetails__text storiesDetails__text storiesDetails__text--first mb-30">
                                                {newsletter.description}
                                            </p>
                                            {/* <p className="donationDetails__text mb-30">{newsletter.details}</p> */}
                                            <h4 className="donationDetails__heading mb-25">If you need to join this event</h4>
                                          <div className='' style={{height:'70px'}}>
                                          <Link
                                                className="btn btn--styleOne btn--primary it-btn"
                                                style={{position: 'absolute', bottom:'30px'}}
                                                to="/donation-listing"
                                            >
                                                <span className="btn__text">join the journey</span>
                                                <i className="fa-solid fa-heart btn__icon"></i>
                                                <span className="it-btn__inner">
                                                    <span className="it-btn__blobs">
                                                        <span className="it-btn__blob"></span>
                                                        <span className="it-btn__blob"></span>
                                                        <span className="it-btn__blob"></span>
                                                        <span className="it-btn__blob"></span>
                                                    </span>
                                                </span>
                                                <svg className="it-btn__animation" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                                    <defs>
                                                        <filter>
                                                            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"></feGaussianBlur>
                                                            <feColorMatrix
                                                                in="blur"
                                                                mode="matrix"
                                                                values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                                                                result="goo"
                                                            ></feColorMatrix>
                                                            <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                                        </filter>
                                                    </defs>
                                                </svg>
                                            </Link>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>


            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default Newsletter;
