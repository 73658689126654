import React, { useEffect, useRef } from "react";
import Services from "../../api/service";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

const ServiceSection = (props) => {

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  const serviceLength = Services.slice(props.startIndex, props.endIndex).length;

  useEffect(() => {
    if (swiperRef.current && prevRef.current && nextRef.current) {
      swiperRef.current.params.navigation.prevEl = prevRef.current;
      swiperRef.current.params.navigation.nextEl = nextRef.current;
      swiperRef.current.navigation.init();
      swiperRef.current.navigation.update();
    }
  }, []);

  const handlePrevClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev(); 
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext(); 
    }
  };

  return (
    <section className="feature cc-slide-wrap2 pt-40 pb-40">
      <div className="container">
        <div className={`row align-items-center ps-3 ps-lg-0 ${props.center ? 'justify-content-center' : ''}`}>
          <div className="col-lg-8 mb-20">
            <div className={`sectionTitle ${props.center ? 'text-center w-100' : ''}`}>
              <span className={`sectionTitle__small ${props.center ? 'text-center d-flex justify-content-center' : ''}`}>
                <i className="fa-solid fa-heart btn__icon"></i>
                {props.subtitle}
              </span>
              <h2 className="sectionTitle__big">{props.title}</h2>
            </div>
            <p className={` ${props.center ? 'text-center' : ''} mb-0`} style={{ textAlign: "justify" }}>
              {props.para}
            </p>
          </div>
          {/* Conditionally render navigation buttons */}
          {serviceLength > 4 && (
            <div className="col-lg-3 mb-15">
              <div className="sliderNav sliderNav--style1">
                <span
                  className="sliderNav__btn btn-prev"
                  tabIndex="0"
                  role="button"
                  // ref={prevRef}
                  onClick={handlePrevClick}
                >
                  <svg
                    width="15"
                    height="10"
                    viewBox="0 0 15 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.75 9.40625L6.375 8.78125C6.5 8.625 6.5 8.40625 6.34375 8.25L3.84375 5.8125H14.625C14.8125 5.8125 15 5.65625 15 5.4375V4.5625C15 4.375 14.8125 4.1875 14.625 4.1875H3.84375L6.34375 1.78125C6.5 1.625 6.5 1.40625 6.375 1.25L5.75 0.625C5.59375 0.5 5.375 0.5 5.21875 0.625L1.09375 4.75C0.96875 4.90625 0.96875 5.125 1.09375 5.28125L5.21875 9.40625C5.375 9.53125 5.59375 9.53125 5.75 9.40625Z"
                      fill="white"
                    ></path>
                  </svg>
                </span>
                <span
                  className="sliderNav__btn btn-next"
                  tabIndex="0"
                  role="button"
                  // ref={nextRef}
                  onClick={handleNextClick}
                >
                  <svg
                    width="15"
                    height="10"
                    viewBox="0 0 15 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.21875 0.625L8.59375 1.25C8.46875 1.40625 8.46875 1.625 8.625 1.78125L11.125 4.1875H0.375C0.15625 4.1875 0 4.375 0 4.5625V5.4375C0 5.65625 0.15625 5.8125 0.375 5.8125H11.125L8.625 8.25C8.46875 8.40625 8.46875 8.625 8.59375 8.78125L9.21875 9.40625C9.375 9.53125 9.59375 9.53125 9.75 9.40625L13.875 5.28125C14 5.125 14 4.90625 13.875 4.75L9.75 0.625C9.59375 0.5 9.375 0.5 9.21875 0.625Z"
                      fill="white"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="featureActive">
              <Swiper
                modules={[Navigation]}
                spaceBetween={15}
                slidesPerView={1}
                slidesPerGroup={1}
                loop={false}
                speed={1800}
                // parallax={true}
                ref={swiperRef}
                onAfterInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
                breakpoints={{
                  576: {
                    slidesPerView: 2,
                  },
                  1025: {
                    slidesPerView: 3,
                  },
                  1200: {
                    slidesPerView: 4,
                  },
                }}
              >
                {(props.data ? props.data : Services.slice(props.startIndex, props.endIndex)).map(
                  (service, srv) => (
                    <SwiperSlide key={srv}>
                      <div
                        className={`keyFeatureBlock keyFeatureBlock--style3 pb-0 ${props.className}`}
                      // style={{borderRadius: "10px"}}
                      >
                        <div className={`keyFeatureBlock__left ${props.appLogo ? 'logo-resp-height' : ''}`}>
                          <span className="keyFeatureBlock__icon">
                            <img
                              src={service.icon}
                              width={props.appLogo ? '75' : 65}
                              alt="Feature Icon"
                            />
                          </span>
                        </div>
                        <div className="keyFeatureBlock__content">
                          <h3 className="keyFeatureBlock__heading">
                            <div className="keyFeatureBlock__heading__link">
                              {service.title}
                            </div>
                          </h3>
                          <p
                            className="keyFeatureBlock__text mb-1"
                            style={{ textAlign: "justify" }}
                          >
                            {service.description}
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </div>
          </div>
        </div>
        {props.endPara && (
          <div className="d-flex w-100 justify-content-center align-items-center">
            <p
              className="text-center"
              style={{ fontSize: "22px", textAlign: "justify" }}
            >
              {props.endPara}
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default ServiceSection;
