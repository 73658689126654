import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ContactForm = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const validateForm = () => {
    const newErrors = {};

    // Validate Name
    if (!forms.name.trim()) {
      newErrors.name = "Full Name is required.";
    } else if (!/^[A-Za-z\s]{2,50}$/.test(forms.name.trim())) {
      newErrors.name =
        "Full Name must be 2-50 characters and only contain letters and spaces.";
    }

    // Validate Email
    if (!forms.email.trim()) {
      newErrors.email = "Email ID is required.";
    } else if (forms.email.trim().length > 254) {
      newErrors.email = "Email ID must be 254 characters or fewer.";
    } else if (!/^[a-zA-Z0-9]+[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(forms.email.trim())) {
      newErrors.email = "Please enter a valid Email ID.";
    } else if (/\.\./.test(forms.email)) {
      newErrors.email = "Email ID cannot contain consecutive dots.";
    }

    // Validate Phone
    console.log(forms);
    if (!forms.phone) {
      newErrors.phone = "Contact Number is required.";
    } else if (!/^\d+$/.test(forms.phone)) {
      newErrors.phone = "Contact Number should only contain numbers.";
    } else if (phoneError) {
      newErrors.phone = "Contact Number must be valid.";
    }

    // Validate Subject
    if (!forms.subject.trim()) {
      newErrors.subject = "Subject is required.";
    } 
    else if (forms.subject.length < 5) {
      newErrors.subject = "Subjct must be at least 5 characters.";
    } else if (forms.subject.length > 100) {
      newErrors.subject = "Subject cannot exceed 100 characters.";
    }

    // Validate Message
    if (!forms.message.trim()) {
      newErrors.message = "Message is required.";
    } else if (forms.message.length < 10) {
      newErrors.message = "Message must be at least 10 characters.";
    } else if (forms.message.length > 500) {
      newErrors.message = "Message cannot exceed 500 characters.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setForms({ ...forms, [name]: value.trimStart() });
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      // await fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/enquiry`, {
      await fetch(`https://marscms-api.mini91.com/api/enquiry`, {
        method: "POST",
        body: JSON.stringify({
          ...forms,
          mobile: forms.phone,
          ccode: forms.phoneCode,
          site: "6798f689e5b0d99195ac3eb8",
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then(async (response) => {
          console.log(response.status);
          if (response?.ok) {
            setSuccessMessage("Message Sent Successfully!");
            setForms({
              name: "",
              email: "",
              phone: "",
              subject: "",
              message: "",
              phoneCode: "91",
            });
            setErrors({});
            setTimeout(() => setSuccessMessage(""), 5000);
          } else {
            setSuccessMessage("");
            setForms({
              name: "",
              email: "",
              phone: "",
              subject: "",
              message: "",
              phoneCode: "91",
            });
            let error = await response.json();
            setErrors({ submit: error.error });
          }
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
    }
  };

  return (
    <form
      id="contact-form"
      className="it-contact-form commentsPost commentsPost--style2 ptt-20 pb-25"
      onSubmit={submitHandler}
    >
      <div className="row g-4">
        {/* Name Input */}
        <div className="col-md-6">
          <div className="commentsPost__input">
            <input
              value={forms.name}
              type="text"
              name="name"
              className="form-control"
              onChange={changeHandler}
              placeholder="Full Name*"
              maxLength="50"
            />
            {errors.name && <p className="error-message mb-0">{errors.name}</p>}
          </div>
        </div>
        {/* Email Input */}
        <div className="col-md-6">
          <div className="commentsPost__input">
            <input
              value={forms.email}
              type="email"
              name="email"
              className="form-control"
              onChange={changeHandler}
              placeholder="Email ID*"
              maxLength="100"
            />
            {errors.email && <p className="error-message mb-0">{errors.email}</p>}
          </div>
        </div>
        {/* Phone Input */}
        <div className="col-md-6">
          <div className="commentsPost__input">
            <PhoneInput
              className="phone-focuss"
              preferredCountries={["in"]}
              placeholder="+91 95 5289 5289"
              onFocus={handleFocus}
              enableLongNumbers={true}
              onBlur={handleBlur}
              value={forms.phoneCode + forms.phone}
              buttonStyle={{
                borderTop: isFocused ? "phone-focuss" : "1px solid #eeeeee",
                borderBottom: isFocused ? "phone-focuss" : "1px solid #eeeeee",
                borderLeft: isFocused ? "phone-focuss" : "1px solid #eeeeee",
                borderRight: isFocused
                  ? "1px solid #eeeeee"
                  : "1px solid #eeeeee",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                borderTopLeftRadius: "6px",
                borderBottomLeftRadius: "6px",
                width: isFocused ? "52px" : "52px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
              }}
              dropdownStyle={{
                top: "50px",
                left: "0px",
              }}
              country={"in"}
              inputStyle={{
                borderRadius: "6px",
                fontSize: "16px",
                border: isFocused ? "phone-focuss" : "1px solid #eeeeee",
                marginLeft: "15px",
                color: "#344054",
                width: isFocused ? "96%" : "96%",
                height: isFocused ? "44px" : "48px",
                outline: "none",
                boxShadow: "none",
              }}
              onChange={(value, country, e, formattedValue) => {
                const phone = formattedValue.split(" ");

                if (/^[1-5]/.test(formattedValue.replace(/\s+/g, ""))) {
                  setPhoneError(true);
                  return;
                } else {
                  setPhoneError(false);
                }
                console.log(country.format.length, formattedValue.length);
                if (
                  country.format.length === formattedValue.length ||
                  (country.dialCode === "971" &&
                    (formattedValue.length === 16 ||
                      formattedValue.length === 18))
                ) {
                  setPhoneError(false);
                } else {
                  setPhoneError(true);
                }

                const newPhone = phone
                  .filter((ph, i) => i !== 0)
                  .join("")
                  .replace("-", "");

                setForms({
                  ...forms,
                  phone: newPhone,
                  phoneCode: country.dialCode,
                });
              }}
            />
            {errors.phone && <p className="error-message mb-0">{errors.phone}</p>}
          </div>
        </div>
        {/* Subject Input */}
        <div className="col-md-6">
          <div className="commentsPost__input">
            <input
              value={forms.subject}
              type="text"
              name="subject"
              className="form-control"
              onChange={changeHandler}
              placeholder="Subject*"
              maxLength="100"
            />
            {errors.subject && (
              <p className="error-message mb-0">{errors.subject}</p>
            )}
          </div>
        </div>
        {/* Message Input */}
        <div className="col-12">
          <div className="commentsPost__input">
            <textarea
              value={forms.message}
              name="message"
              className="form-control"
              onChange={changeHandler}
              placeholder="Message*"
              maxLength="500"
            ></textarea>
            {errors.message && (
              <p className="error-message mb-0">{errors.message}</p>
            )}
          </div>
        </div>
        {/* Submit Button */}
        <div className="col-12 mt-30">
          <div className="commentsPost__button text-center">
            <button
              type="submit"
              className="btn btn--styleOne btn--primary it-btn"
            >
              <span className="btn__text">Send</span>
              <i className="fa-solid fa-heart btn__icon"></i>
            </button>
          </div>
        </div>
      </div>
      {successMessage && (
        <div className="success-messege mt-3 text-center">{successMessage}</div>
      )}
      {errors?.submit && (
        <div className="error-message mt-3 text-center">{errors?.submit}</div>
      )}
    </form>
  );
};

export default ContactForm;
