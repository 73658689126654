import React, { Fragment } from "react";
import Header from "../../components/header/Header";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import About2 from "../../components/about2/about2";
import Services from "../../api/service";
import { Link } from "react-router-dom";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import Breadcumb from "../../images/donate/breadcumb.jpg";
import AboutUs from "../../images/donate/about-us.jpg";
// import OnlineDonation from "../../images/donate/donation-2.png";
import DonateInKind from "../../images/donate/donation-1.png";
import CorporateDonation from "../../images/donate/coporate-donation.png";
import SevakArmy from "../../images/donate/sevak-army.png";
import LifeSaverArmy from "../../images/home/lfe-saver-logo.png";
import MyFoodChecker from "../../images/home/food-checker-logo.png";
import MindUnload from "../../images/donate/mind-unload.png";
import AkshayNetra from "../../images/home/akshay-netra.png";
import MainCta from "../../images/donate/main-cta.jpg";
import ReformArmy from '../../images/home/reform-army-logo.png'
import YoungJains from '../../images/home/young-jain.png'
import PledgeOrgans from '../../images/home/pledge-organs-logo-1.png'
import { Slide } from "react-awesome-reveal";

const Features2 = [
  {
    title: "Sevak Army",
    des: "Mobilising volunteers to tackle pressing social challenges.",
    icon: SevakArmy,
    width: "80%",
    duration: 1000,
  },
  {
    title: "Life Saver Army",
    des: "Connecting blood donors with patients in need.",
    icon: LifeSaverArmy,
    width: "100%",
    duration: 1200,
  },
  {
    title: "My Food Checker",
    des: "Promoting healthy eating and food safety.",
    icon: MyFoodChecker,
    width: "70%",
    duration: 1400,
  },
  {
    title: "Mind Unload",
    des: "Supporting mental well-being through stress-relief activities and resources.",
    icon: MindUnload,
    width: "70%",
    duration: 1400,
  },
  {
    title: "Akshay Netra",
    des: "Spreading awareness and coordinating eye pledges to eliminate preventable blindness.",
    icon: AkshayNetra,
    width: "70%",
    duration: 1400,
  },
  {
    title: "Young Jains",
    des: "Promotes Jain values through engaging workshops, teaching kindness, mindfulness, and nonviolence",
    icon: YoungJains,
    width: "70%",
    duration: 1400,
  },
  {
    title: "Reform Army",
    des: "Connects people with decision-makers to drive change through impactful advocacy campaigns.",
    icon: ReformArmy,
    width: "70%",
    duration: 1400,
  },
  {
    title: "Pledge My Organs",
    des: "Helps individuals pledge their organs, raising awareness and simplifying the process.",
    icon: PledgeOrgans,
    width: "70%",
    duration: 1400,
  },
];


const Donate = (props) => {


  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };



  return (
    <Fragment>
      <Header hclass={"header--styleFour"} />
      <main className="page_content">
        <PageTitle pageTitle={"Donate"} pagesub={"Join Us"} bg={Breadcumb} />
        <div className="pt-40 pb-40">
          <About2
            subtitle="Donate to Jainam Jivika Foundation"
            img={AboutUs}
            title="Your Contribution Can Change Lives"
            para="At Jainam Jivika Foundation, we strive to make a positive difference in the lives of those in need. By donating, you directly contribute to our initiatives that support education, healthcare, environmental sustainability, cultural preservation and social welfare. Every rupee counts towards building a brighter, more inclusive future."
          />
        </div>

        <section className="feature pt-40 pb-20 gray-bg" id="feature">
          <div className="container">
            <div className="sectionTitle mb-20 justify-content-center">
              <span className="sectionTitle__small d-flex justify-content-center">
                <i className="fa-solid fa-heart btn__icon"></i>
                Your Contribution, Their Future
              </span>
              <h2 className="sectionTitle__big text-center">
                Why Donate to Jainam Jivika Foundation?
              </h2>
            </div>
            <div className="row gx-35 pt-3">
              {Services.slice(49, 52).map((service, srv) => (
                <div className="col-lg-4" key={srv}>
                  <div className="keyFeatureBlock keyFeatureBlock--style2 mb-30 why-donate-responsive">
                    <div className="keyFeatureBlock__left">
                      <span className="keyFeatureBlock__icon">
                        <img
                          src={service.icon}
                          width={70}
                          alt="Hope in Action"
                        />
                      </span>
                    </div>
                    <div className="keyFeatureBlock__content">
                      <h3 className="keyFeatureBlock__heading">
                        <div className="keyFeatureBlock__heading__link">
                          {service.title}
                        </div>
                      </h3>
                      <p
                        className="keyFeatureBlock__text"
                        style={{ textAlign: "justify" }}
                      >
                        {service.description}
                      </p>
                      {/* <Link onClick={ClickHandler} to='/' className="keyFeatureBlock__link">
                                                <span className="keyFeatureBlock__link__dot"></span>
                                                <span className="keyFeatureBlock__link__dot"></span>
                                                <span className="keyFeatureBlock__link__dot"></span>
                                            </Link> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* <DonationListSection /> */}

        {/* <About4 /> */}
        <div className="white-bg">
          <ServiceSection
            subtitle="Every Rupee Counts, Every Action Inspires Change"
            title="How Your Donation Helps?"
            startIndex="52"
            endIndex="57"
          />
        </div>

        <section className="review pt-40 pb-40 position-relative overflow-hidden gray-bg">
          <div className="container">
            <div className="sectionTitle">
              <span className="sectionTitle__small justify-content-start">
                <i className="fa-solid fa-heart btn__icon"></i>
                Choose How You Can Give, Make an Impact Today
              </span>
              <h2 className="sectionTitle__big">Ways to Donate</h2>
            </div>
            <div className="row mt-3">
              <div className="col-lg-5">

                <div className="reviewMap">
                  <div className="team_member_details_card gray-bg col-lg-11">
                    <div className="team_member_content">
                      <h2 className="team_member_name mb-2">
                        <a
                          href="https://www.jjfindia.com/donate-now.html"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Bank Transfer
                        </a>
                      </h2>
                      <p className="mt-0" style={{ fontSize: "18px" }}>
                        Transfer Directly to Our Account{" "}
                      </p>
                      <ul className="icon_list unordered_list_block">
                        <li>
                          <span className="icon_list_text wrap-text">
                            <strong>Name: </strong>
                            Jainam Jivika Foundation
                          </span>
                        </li>
                        <li>
                          <span className="icon_list_text wrap-text">
                            <strong>Account No.: </strong>
                            023100100000097
                          </span>
                        </li>
                        <li>
                          <span className="icon_list_text wrap-text">
                            <strong>Bank Name: </strong>
                            <span className="wrap-text">
                              {" "}
                              The Nasik Merchants Co-operative Bank Ltd. Nashik
                            </span>
                          </span>
                        </li>
                        <li>
                          <span className="icon_list_text wrap-text">
                            <strong>Branch Name: </strong>
                            Malegaon
                          </span>
                        </li>
                        <li>
                          <span className="icon_list_text wrap-text">
                            <strong>Account Type: </strong>
                            Current Account
                          </span>
                        </li>
                        <li>
                          <span className="icon_list_text wrap-text">
                            <strong>IFSC: </strong>
                            NMCB0000024
                          </span>
                        </li>
                      </ul>
                      {/* <div className="social_wrapper">
                        <h3 className="social_title">Social Media</h3>
                        <ul className="social_icons_block unordered_list">
                          <li>
                          <a
                              className="twitter"
                              href="https://www.facebook.com/TeamJJFIndia/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              className="twitter"
                              href="https://x.com/TeamJJFIndia/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="">
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.13303 6.062L12.5632 0.875H11.5132L7.66753 5.37862L4.59453 0.875H1.05078L5.69703 7.686L1.05078 13.125H2.10078L6.16253 8.3685L9.40791 13.125H12.9517L8.13303 6.062ZM6.69541 7.7455L6.22466 7.06737L2.47878 1.67125H4.09141L7.11366 6.02613L7.58441 6.70425L11.514 12.3655H9.90141L6.69541 7.7455Z"
                                    fill="black"
                                  />
                                </svg>
                              </i>
                            </a>
                          </li>
                          <li>
                            <a
                              className="instagram"
                              href="https://www.instagram.com/TeamJJFIndia/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fab fa-instagram"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              className="linkedin"
                              href="https://www.linkedin.com/company/TeamJJFIndia/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fab fa-linkedin-in"></i>
                            </a>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="row align-items-stretch" style={{ height: 'fit-content' }}>
                  {/* <div className="col-lg-6">
                    <div className="reviewblock reviewblock--style2">
                      <div className="reviewblock__content">
                        <div className="reviewblock__author">
                          <div className="reviewblock__authorImage">
                            <img src={OnlineDonation} alt="Join the Movementr" />
                          </div>
                          <h3 className="reviewblock__authorName">
                            Online Donation{" "}
                          </h3>
                          <p
                            className="reviewblock__authorSpeech"
                            style={{ textAlign: "justify" }}
                          >
                            Make a secure payment through our website and
                            support causes that make a real difference in the
                            community.
                          </p>
                          <div className="aboutContent__buttons">
                            <Link
                              to="#"
                              onClick={ClickHandler}
                              className="btn btn--styleOne btn--secondary it-btn"
                            >
                              <span className="btn__text">
                                Donate Online Now
                              </span>
                              <i className="fa-solid fa-heart btn__icon"></i>
                              <span className="it-btn__inner">
                                <span className="it-btn__blobs">
                                  <span className="it-btn__blob"></span>
                                  <span className="it-btn__blob"></span>
                                  <span className="it-btn__blob"></span>
                                  <span className="it-btn__blob"></span>
                                </span>
                              </span>
                              <svg
                                className="it-btn__animation"
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                              >
                                <defs>
                                  <filter>
                                    <feGaussianBlur
                                      in="SourceGraphic"
                                      result="blur"
                                      stdDeviation="10"
                                    ></feGaussianBlur>
                                    <feColorMatrix
                                      in="blur"
                                      mode="matrix"
                                      values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                                      result="goo"
                                    ></feColorMatrix>
                                    <feBlend
                                      in2="goo"
                                      in="SourceGraphic"
                                      result="mix"
                                    ></feBlend>
                                  </filter>
                                </defs>
                              </svg>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="col-lg-6">
                    <div className="reviewblock reviewblock--style2 col-lg-11">
                      <div className="reviewblock__content">
                        <div className="reviewblock__author">
                          <div className="reviewblock__authorImage">
                            <img src={DonateInKind} alt="Join the Movementr" />
                          </div>
                          <h3 className="reviewblock__authorName" style={{ fontSize: '22px' }}>
                            Donate In-Kind{" "}
                          </h3>
                          <p
                            className="reviewblock__authorSpeech"
                            style={{ textAlign: "justify", fontSize: "18px" }}
                          >
                            Contribute items like clothes, food or school
                            supplies. Contact us to arrange pickup or delivery.
                          </p>
                          <div className="aboutContent__buttons mt-4">
                            <Link
                              to="/contact-us"
                              onClick={ClickHandler}
                              className="btn btn--styleOne btn--secondary it-btn"
                            >
                              <span className="btn__text">Contact Us</span>
                              <i className="fa-solid fa-heart btn__icon"></i>
                              <span className="it-btn__inner">
                                <span className="it-btn__blobs">
                                  <span className="it-btn__blob"></span>
                                  <span className="it-btn__blob"></span>
                                  <span className="it-btn__blob"></span>
                                  <span className="it-btn__blob"></span>
                                </span>
                              </span>
                              <svg
                                className="it-btn__animation"
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                              >
                                <defs>
                                  <filter>
                                    <feGaussianBlur
                                      in="SourceGraphic"
                                      result="blur"
                                      stdDeviation="10"
                                    ></feGaussianBlur>
                                    <feColorMatrix
                                      in="blur"
                                      mode="matrix"
                                      values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                                      result="goo"
                                    ></feColorMatrix>
                                    <feBlend
                                      in2="goo"
                                      in="SourceGraphic"
                                      result="mix"
                                    ></feBlend>
                                  </filter>
                                </defs>
                              </svg>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="reviewblock reviewblock--style2 col-lg-11">
                      <div className="reviewblock__content">
                        <div className="reviewblock__author">
                          <div className="reviewblock__authorImage">
                            <img
                              src={CorporateDonation}
                              alt="Join the Movementr"
                            />
                          </div>
                          <h3 className="reviewblock__authorName" style={{ fontSize: '22px' }}>
                            Corporate Donations
                          </h3>
                          <p
                            className="reviewblock__authorSpeech"
                            style={{ textAlign: "justify", fontSize: "18px" }}
                          >
                            Partner with us today as part of your impactful Corporate Social Responsibility (CSR) initiatives.
                          </p>
                          <div className="aboutContent__buttons mt-4">
                            <Link
                              to="/corporate-donations"
                              onClick={ClickHandler}
                              className="btn btn--styleOne btn--secondary it-btn"
                            >
                              <span className="btn__text">Learn More</span>
                              <i className="fa-solid fa-heart btn__icon"></i>
                              <span className="it-btn__inner">
                                <span className="it-btn__blobs">
                                  <span className="it-btn__blob"></span>
                                  <span className="it-btn__blob"></span>
                                  <span className="it-btn__blob"></span>
                                  <span className="it-btn__blob"></span>
                                </span>
                              </span>
                              <svg
                                className="it-btn__animation"
                                xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                              >
                                <defs>
                                  <filter>
                                    <feGaussianBlur
                                      in="SourceGraphic"
                                      result="blur"
                                      stdDeviation="10"
                                    ></feGaussianBlur>
                                    <feColorMatrix
                                      in="blur"
                                      mode="matrix"
                                      values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                                      result="goo"
                                    ></feColorMatrix>
                                    <feBlend
                                      in2="goo"
                                      in="SourceGraphic"
                                      result="mix"
                                    ></feBlend>
                                  </filter>
                                </defs>
                              </svg>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>

        <div className="white-bg">
          {/* <About3
            bg="gray-bg"
            ctaSection={false}
            subtitle="Empowering Change, One Initiative at a Time"
            title="Our Key Initiatives"
            className="donate-key-features"
            para="Your donation supports impactful programmes, including:"
            Features2={Features2}
            isHeight={false}
            iconSize ={120}
            width={80}
            ctabutton={false}
            button="KNOW MORE"
          /> */}

          <div className="services services--style3 pb-4">
            <div className="container">
              <div className="row mb-4">
                <div className="col-lg-6 maindiv">
                  <div className="sectionTitle sectionntitle  mb-30">
                    <span className="sectionTitle__small sectionSubtitle ">
                      <i className="fa-solid fa-heart btn__icon"></i>
                      Empowering Change, One Initiative at a Time
                    </span>
                    <h2 className="sectionTitle__big">Our Key Initiatives</h2>
                    <p className="mb-0" style={{ fontSize: "20px" }}>
                      Your donation supports impactful programmes, including:
                    </p>
                  </div>
                </div>
              </div>
              <div className={`row gx-35 pt-35 justify-content-center gy-45 align-items-stretch flex-wrap px-2`}>
                {Features2.map((features, fitem) => (
                  <div className={` d-flex flex-wrap mb-3 col-xl-3 col-lg-6 col-sm-11 col-12 mt-0`} key={fitem}>
                    <Slide
                      direction="up"
                      triggerOnce={"false"}
                      duration={features.duration}
                      style={{ width: '100%' }}
                    >
                      <div className="keyFeatureBlock keyFeatureBlock--style4 mb-30 w-100 d-flex flex-column h-100 pt-3">
                        <div className="keyFeatureBlock__content flex-grow-1">
                          <h3 className="keyFeatureBlock__heading">
                            <div className="keyFeatureBlock__heading__link">
                              {features.title}
                            </div>
                          </h3>
                          <p
                            className="keyFeatureBlock__text"
                            style={{ textAlign: "justify" }}
                          >
                            {features.des}
                          </p>
                        </div>
                        {features.auth && (
                          <div className="keyFeatureBlock__content mt-2 d-flex w-100 justify-content-end">
                            <p
                              className="keyFeatureBlock__text"
                              style={{
                                width: "100%",
                                textAlign: "right",
                                fontWeight: "800",
                              }}
                            >
                              - {features.auth}
                            </p>
                          </div>
                        )}
                      </div>
                    </Slide>
                  </div>
                ))}
              </div>

              {props.ctabutton && (
                <>
                  <div className="aboutContent__buttons d-flex justify-content-center mt-4">
                    <Link
                      to="/contact-us"
                      onClick={ClickHandler}
                      className="btn btn--styleOne btn--secondary it-btn"
                    >
                      <span className="btn__text">{props.button}</span>
                      <i className="fa-solid fa-heart btn__icon"></i>
                      <span className="it-btn__inner">
                        <span className="it-btn__blobs">
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                          <span className="it-btn__blob"></span>
                        </span>
                      </span>
                      <svg
                        className="it-btn__animation"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                      >
                        <defs>
                          <filter>
                            <feGaussianBlur
                              in="SourceGraphic"
                              result="blur"
                              stdDeviation="10"
                            ></feGaussianBlur>
                            <feColorMatrix
                              in="blur"
                              mode="matrix"
                              values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                              result="goo"
                            ></feColorMatrix>
                            <feBlend
                              in2="goo"
                              in="SourceGraphic"
                              result="mix"
                            ></feBlend>
                          </filter>
                        </defs>
                      </svg>
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>


        </div>

        <div className="pt-40 gray-bg pb-40">
          <div className="volunteer">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-10">
                  <div className="volunteerUser">
                    <div className="volunteerUser__box">
                      <div className="volunteerUser__thumb">
                        <img src={MainCta} alt="Join Us and Make a Difference" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="aboutContent aboutContent--style3">
                    <div className="sectionTitle mb-20">
                      <span className="sectionTitle__small">
                        <i className="fa-solid fa-heart btn__icon"></i>
                        Your Support, Their Future
                      </span>
                      <h2 className="sectionTitle__big">Your Impact Matters</h2>
                    </div>
                    <p
                      className="aboutContent__text"
                      style={{ textAlign: "justify" }}
                    >
                      Every donation, no matter how big or small, makes a
                      difference. Together, we can uplift communities, empower
                      individuals and create opportunities for a brighter
                      future.
                    </p>
                    <div className="aboutContent__buttons">
                      <Link
                        to="/contact-us"
                        onClick={ClickHandler}
                        className="btn btn--styleOne btn--secondary it-btn"
                        style={{ width: '345px' }}
                      >
                        <span className="btn__text" style={{ whiteSpace: 'nowrap' }}>
                          Contact Us for More Information
                        </span>
                        <i className="fa-solid fa-heart btn__icon"></i>
                        <span className="it-btn__inner">
                          <span className="it-btn__blobs">
                            <span className="it-btn__blob"></span>
                            <span className="it-btn__blob"></span>
                            <span className="it-btn__blob"></span>
                            <span className="it-btn__blob"></span>
                          </span>
                        </span>
                        <svg
                          className="it-btn__animation"
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                        >
                          <defs>
                            <filter>
                              <feGaussianBlur
                                in="SourceGraphic"
                                result="blur"
                                stdDeviation="10"
                              ></feGaussianBlur>
                              <feColorMatrix
                                in="blur"
                                mode="matrix"
                                values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                                result="goo"
                              ></feColorMatrix>
                              <feBlend
                                in2="goo"
                                in="SourceGraphic"
                                result="mix"
                              ></feBlend>
                            </filter>
                          </defs>
                        </svg>
                      </Link>
                      {/* <Link
                        onClick={ClickHandler}
                        to="/volunteer-opportunities"
                        className="btn btn--styleOne btn--primary it-btn"
                        style={{ width: '345px', display: 'flex', justifyContent: 'center' }}
                      >
                        <span className="btn__text text-center">
                          Donate Now to Make a Change
                        </span>
                        <i className="fa-solid fa-heart btn__icon"></i>
                        <span className="it-btn__inner">
                          <span className="it-btn__blobs">
                            <span className="it-btn__blob"></span>
                            <span className="it-btn__blob"></span>
                            <span className="it-btn__blob"></span>
                            <span className="it-btn__blob"></span>
                          </span>
                        </span>
                        <svg
                          className="it-btn__animation"
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                        >
                          <defs>
                            <filter>
                              <feGaussianBlur
                                in="SourceGraphic"
                                result="blur"
                                stdDeviation="10"
                              ></feGaussianBlur>
                              <feColorMatrix
                                in="blur"
                                mode="matrix"
                                values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                                result="goo"
                              ></feColorMatrix>
                              <feBlend
                                in2="goo"
                                in="SourceGraphic"
                                result="mix"
                              ></feBlend>
                            </filter>
                          </defs>
                        </svg>
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default Donate;
