import React, { Fragment } from 'react';
import Header from '../../components/header/Header';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Footer from '../../components/footer/Footer';
import About2 from '../../components/about2/about2';
import { Link } from 'react-router-dom';
import Breadcumb from '../../images/acheivements/breadcumb.jpg'
import AboutUs from '../../images/acheivements/about-us.png'
import SevakArmy from '../../images/acheivements/sevak-army.jpg'
import Education from '../../images/acheivements/education.jpeg'
import Disaster from '../../images/acheivements/disaster.jpeg'
import Health from '../../images/acheivements/health.jpeg'
import Environment from '../../images/acheivements/environment.jpeg'
import CtaMain from '../../images/acheivements/looking-ahead.png'


const Achievements = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <Fragment>
            <Header hclass={'header--styleFour'} />
            <main className="page_content">
                <PageTitle pageTitle={'Achievements'} pagesub={'Impacts & Stories'} bg={Breadcumb} />

                <div className='pt-40 pb-40' >
                    <About2 subtitle="Achievements and Milestones" img={AboutUs} title="Celebrating Progress and Impact at Jainam Jivika Foundation" para={<>At Jainam Jivika Foundation, every milestone reflects our unwavering commitment to bringing positive change to communities across India. From impactful initiatives to inspiring collaborations, our journey continues to make a difference, one step at a time.</>} />
                </div>


                <section className="events gray-bg pt-40" id='feature'>
                    <div className="container">
                        <div className="sectionTitle">
                            <span className="sectionTitle__small d-flex w-100 sectionSubtitle">
                                <i className="fa-solid fa-heart btn__icon"></i>
                                Transforming Lives, One Step at a Time
                            </span>
                            <h2 className="sectionTitle__big sectionntitle">Key Achievements</h2>
                        </div>
                        <div className="row mt-30">
                            <div className="col-12 mb-30">
                                <div className="eventsBlock flex-nowrappp">
                                    <figure className="eventsBlock__thumb col-lg-4">
                                        <a className="eventsBlock__thumb__link">
                                            <img src={SevakArmy} alt="Join the Movement" className="eventsBlock__thumb__image" />
                                        </a>
                                    </figure>
                                    <div className="eventsBlock__content mt-10 col-lg-8">
                                        <h3 className="eventsBlock__heading text-uppercase wrap-text">
                                            <a>Launch of Sevak Army on 2nd October</a>
                                        </h3>
                                        <p className='eventsBlock__text mb-4 wrap-text' style={{textAlign:'justify'}}>On Gandhi Jayanti, a day symbolising service and selflessness, Jainam Jivika Foundation proudly launched Sevak Army, our volunteer-driven platform to empower communities.</p>
                                        <div className="eventsBlock__meta">
                                            <ul className=''>
                                                <li style={{textAlign:'justify'}}>
                                                    <span className="eventsBlock__meta__title">What it Does:</span>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}>  Connects individuals with NGOs to create meaningful social impact through volunteering and service. </span>
                                                </li>
                                                <li style={{textAlign:'justify'}}>
                                                    <span className="eventsBlock__meta__title">Milestones:</span>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Over Over 500 volunteers registered within the first week. </span>
                                                </li>
                                                <li style={{textAlign:'justify'}}>
                                                    <span className="eventsBlock__meta__title">Vision:</span>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> To achieve over 2 million volunteer hours by 2025. </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="eventsBlock__meta__text wrap-text" style={{textAlign:'justify', color: '#666666', }}><span className='eventsBlock__meta__title'><span  className="eventsBlock__meta__title">Highlight:</span> </span> Sevak Army sets a benchmark in collaborative service, inspired by Mahatma Gandhi’s ideals of compassion and unity.
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-12 mb-30">
                                <div className="eventsBlock flex-nowrappp align-items-center">
                                    <figure className="eventsBlock__thumb col-lg-4">
                                        <a className="eventsBlock__thumb__link">
                                            <img src={Education} alt="Join the Movement" className="eventsBlock__thumb__image" />
                                        </a>
                                    </figure>
                                    <div className="eventsBlock__content mt-10 col-lg-8">
                                        <h3 className="eventsBlock__heading text-uppercase">
                                            <a>Successful Educational Drives</a>
                                        </h3>
                                        <p className='eventsBlock__text mb-4 wrap-text' style={{textAlign:'justify'}}>Our flagship educational programmes have reached over 10,000 students in underserved areas.</p>
                                        <div className="eventsBlock__meta">
                                            <ul>
                                                <li style={{textAlign:'justify'}}>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Digital learning tools and resources distributed to rural schools.</span>
                                                </li>
                                                <li style={{textAlign:'justify'}}>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Mentorship sessions are conducted to build confidence and skills. </span>
                                                </li>
                                                <li style={{textAlign:'justify'}}>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Enriched learning environments have led to a 30% improvement in attendance and performance.</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-30">
                                <div className="eventsBlock flex-nowrappp align-items-center">
                                    <figure className="eventsBlock__thumb col-lg-4">
                                        <a className="eventsBlock__thumb__link">
                                            <img src={Disaster} alt="Join the Movement" className="eventsBlock__thumb__image" />
                                        </a>
                                    </figure>
                                    <div className="eventsBlock__content mt-10 col-lg-8">
                                        <h3 className="eventsBlock__heading text-uppercase">
                                            <a>Disaster Relief Operations</a>
                                        </h3>
                                        <p className='eventsBlock__text mb-4 wrap-text' style={{textAlign:'justify'}}>In response to natural calamities, Jainam Jivika Foundation has mobilised resources to support affected families.</p>
                                        <div className="eventsBlock__meta">
                                            <ul>
                                                <li style={{textAlign:'justify'}}>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Distributed over 50,000 relief kits, including food, water and medical supplies. </span>
                                                </li>
                                                <li style={{textAlign:'justify'}}>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Partnered with local NGOs to ensure quick response times.</span>
                                                </li>
                                                <li style={{textAlign:'justify'}}>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Provided shelter and essential aid to 15,000+ displaced individuals.</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-30">
                                <div className="eventsBlock flex-nowrappp align-items-center">
                                    <figure className="eventsBlock__thumb col-lg-4">
                                        <a className="eventsBlock__thumb__link">
                                            <img src={Health} alt="Join the Movement" className="eventsBlock__thumb__image" />
                                        </a>
                                    </figure>
                                    <div className="eventsBlock__content mt-10 col-lg-8">
                                        <h3 className="eventsBlock__heading text-uppercase">
                                            <a>Health and Well-Being Initiatives</a>
                                        </h3>
                                        <p className='eventsBlock__text mb-4 wrap-text' style={{textAlign:'justify'}}>In response to natural calamities, Jainam Jivika Foundation has mobilised resources to support affected families.</p>
                                        <div className="eventsBlock__meta">
                                            <ul>
                                                <li style={{textAlign:'justify'}}>
                                                    <span className="eventsBlock__meta__title">Akshay Netra:</span>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Enabled 1,000+ successful eye donations, restoring vision and independence. </span>
                                                </li>
                                                <li style={{textAlign:'justify'}}>
                                                    <span className="eventsBlock__meta__title">Mind Unload:</span>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Conducted 250+ mental wellness workshops, benefiting over 5,000 participants.</span>
                                                </li>
                                                <li style={{textAlign:'justify'}}>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Provided shelter and essential aid to 15,000+ displaced individuals.</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 mb-30">
                                <div className="eventsBlock flex-nowrappp align-items-center">
                                    <figure className="eventsBlock__thumb col-lg-4">
                                        <a className="eventsBlock__thumb__link">
                                            <img src={Environment} alt="Join the Movement" className="eventsBlock__thumb__image" />
                                        </a>
                                    </figure>
                                    <div className="eventsBlock__content mt-10 col-lg-8">
                                        <h3 className="eventsBlock__heading text-uppercase">
                                            <a>Environmental Conservation </a>
                                        </h3>
                                        <div className="eventsBlock__meta">
                                            <ul>
                                                <li style={{textAlign:'justify'}}>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Planted 20,000+ trees across India through community drives. </span>
                                                </li>
                                                <li style={{textAlign:'justify'}}>
                                                    <span className="eventsBlock__meta__text wrap-text" style={{ color: '#666666', textTransform: 'none', fontSize: '18px' }}> Organised 50+ clean-up campaigns to promote sustainable living.
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <div className="volunteer pt-40 pb-40">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 mb-10">
                                <div className="volunteerUser">
                                    <div className="volunteerUser__box">
                                        <div className="volunteerUser__thumb">
                                            <img src={CtaMain} width={550} alt="Join Us and Make a Difference" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="aboutContent aboutContent--style3">
                                    <div className="sectionTitle mb-20">
                                        <span className="sectionTitle__small">
                                            <i className="fa-solid fa-heart btn__icon"></i>
                                            Together for a Future of Hope and Impact
                                        </span>
                                        <h2 className="sectionTitle__big">Looking Ahead</h2>
                                    </div>
                                    <p className="aboutContent__text" style={{ textAlign: 'justify' }}>
                                        Jainam Jivika Foundation remains committed to its vision of transforming lives and empowering communities. As we celebrate these milestones, we are inspired to continue our work and invite everyone to join hands with us in this journey of hope, compassion and progress.
                                    </p>
                                    <div className="aboutContent__buttons">
                                        <Link onClick={ClickHandler} className="btn btn--styleOne btn--primary it-btn" to="/contact-us">
                                            <span className="btn__text">Join Our Initiatives</span>
                                            <i className="fa-solid fa-heart btn__icon"></i>
                                            <span className="it-btn__inner">
                                                <span className="it-btn__blobs">
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                    <span className="it-btn__blob"></span>
                                                </span>
                                            </span>
                                            <svg className="it-btn__animation" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                                <defs>
                                                    <filter>
                                                        <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10">
                                                        </feGaussianBlur>
                                                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7" result="goo">
                                                        </feColorMatrix>
                                                        <feBlend in2="goo" in="SourceGraphic" result="mix"></feBlend>
                                                    </filter>
                                                </defs>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default Achievements;
