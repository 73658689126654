import React from "react";
import Phone from "../../images/contact/mobile.png";
import Email from "../../images/contact/mail.png";

const CorporateDonations = (props) => {
  return (
    <div>
      <div
        className="services services--style3 pb-0 pt-40"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="sectionTitle text-center mb-25">
                <span className="sectionTitle__small justify-content-center">
                  <i className="fa-solid fa-heart btn__icon"></i>
                  Corporate Donations
                </span>
                <h2 className="sectionTitle__big">
                  Make a Difference Through Partnership
                </h2>
                <p style={{ fontSize: "18px" }}>
                  Join hands with Jainam Jivika Foundation to create lasting
                  change. Collaborate with us through CSR initiatives, employee
                  engagement programmes or customised campaigns tailored to
                  your company&apos;s values and goals. Together, we can empower
                  communities and create a brighter future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact contact--layout1 pt-0 pb-30" id="feature">
        <div className="container">
          <div className="col-lg-6 mx-auto">
            <div className="sectionTitle text-center mb-25">
              <h2 className="sectionTitle__big" style={{ fontSize: "32px" }}>
                Our Contact Details
              </h2>
            </div>
          </div>
          <div className="row justify-content-center pt-120 gy-65">
            <div className="col-lg-4 col-md-6 col-sm-8 col-11 mb-40">
              <div className="contactBlock text-center">
                <figure className="contactBlock__figure">
                  <img
                    className="contactBlock__figure__thumb"
                    style={{ zIndex: 1 }}
                    src={Email}
                    width={100}
                    alt="Join the Movement"
                  />
                </figure>
                <div className="contactBlock__content">
                  <h2 className="contactBlock__heading text-uppercase">
                    Email Id
                  </h2>
                  <span className="contactBlock__phone connect">
                    <a
                      className="contact-links"
                      target="_blank"
                      rel="noreferrer"
                      href="mailto:info@JJFIndia.com"
                    >
                      {" "}
                      info@jjfindia.com{" "}
                    </a>{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8 col-11 mb-40">
              <div className="contactBlock text-center">
                <figure className="contactBlock__figure">
                  <img
                    className="contactBlock__figure__thumb"
                    style={{ zIndex: 1 }}
                    src={Phone}
                    width={100}
                    alt="Join the Movement"
                  />
                </figure>
                <div className="contactBlock__content">
                  <h2 className="contactBlock__heading text-uppercase">
                    Contact Number
                  </h2>
                  <span className="contactBlock__phone connect">
                    <a className="contact-links" href="tel:+919552895289">
                      +91 95 5289 5289
                    </a>
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 col-sm-8 col-11 mb-40">
              <div className="contactBlock text-center contact-div-heightt">
                <figure className="contactBlock__figure">
                  <img
                    className="contactBlock__figure__thumb"
                    style={{ zIndex: 1 }}
                    src={Address}
                    alt="Join the Movement"
                  />
                </figure>
                <div className="contactBlock__content">
                  <h2 className="contactBlock__heading text-uppercase">
                    Address
                  </h2>
                  <a
                    href="https://maps.app.goo.gl/bSzYRg3pEXfJw7At8"
                    className="contact-links"
                    target="_blank"
                  >
                    <p className="contactBlock__text mb-0">
                      Jainam Jivika Foundation, 3, Jainam Tower, Sai Park,
                    </p>
                    <p className="contactBlock__email connect mb-0">
                      Belthika Nagar, Thergaon,
                    </p>
                    <p className="contactBlock__phone connect">
                      Pune - 411033, Maharashtra, India.
                    </p>
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* <div
        className="services services--style3 pb-50 gray-bg"
        style={{ paddingTop: "100px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="sectionTitle text-center mb-65">
                <span className="sectionTitle__small justify-content-center">
                  <i className="fa-solid fa-heart btn__icon"></i>
                  Corporate Partnership
                </span>
                <h2 className="sectionTitle__big">
                  Let&apos;s partner to make a meaningful impact!
                </h2>
                <p style={{ fontSize: "18px" }}>
                  Fill out the form below to reach us. One of our team members
                  will get back to you as soon as possible.
                </p>
              </div>
            </div>
          </div>
          <CSRForm />
        </div>
      </div> */}
    </div>
  );
};

export default CorporateDonations;
